<template>
  <div class="client-page company-data-page p-tb-16 p-lr-20 flex-column flex-center">
    <!--  个人账号-商家管理员审核中-进入企业资料  -->
    <div v-if="isShowCompanies && companyState === 1" class="width-full flex-column flex-center">
      <div class="text-red font-size-20">商家管理员审核中</div>
      <div class="flex flex-center m-t-20">
        <el-button class="w-150" type="primary" round plain @click="cancelApplication">取消申请</el-button>
        <div class="w-50"></div>
        <el-button
          v-if="companyInfo.servicePhone"
          class="w-150"
          type="primary"
          round
          plain
          @click="servicePhoneVisible = true"
        >
          拨打客服热线
        </el-button>
      </div>
    </div>
    <!--  个人-商家拒绝-进入企业资料  -->
    <div v-if="isShowCompanies && companyState === 2" class="width-full flex-column flex-center">
      <div class="text-red font-size-20">商家审核不通过</div>
      <div class="flex flex-center m-t-20">
        <el-button class="w-150" type="primary" round @click="againApplication">重新申请</el-button>
        <div class="w-50"></div>
        <el-button
          v-if="companyInfo.servicePhone"
          class="w-150"
          type="primary"
          round
          plain
          @click="servicePhoneVisible = true"
        >
          拨打客服热线
        </el-button>
      </div>
    </div>
    <!--  企业账号（管理员）-未发起账号认证-进入企业资料  -->
    <div v-if="isShowCompanies && companyState === 3" class="width-full flex-column flex-center">
      <div class="text-red font-size-20">您的企业尚未成为正式客户</div>
      <div class="flex flex-center m-t-20">
        <el-button v-if="userInfo.isManager" class="w-150" type="primary" round @click="againApplication">
          重新申请
        </el-button>
        <div v-if="userInfo.isManager" class="w-50"></div>
        <el-button
          v-if="companyInfo.servicePhone"
          class="w-150"
          type="primary"
          round
          plain
          @click="servicePhoneVisible = true"
        >
          拨打客服热线
        </el-button>
      </div>
    </div>
    <!--  企业账号（管理员）-待商家审核-进入企业资料  -->
    <div v-if="isShowCompanies && companyState === 4" class="width-full flex-column flex-center">
      <div class="text-red font-size-20">商家管理员审核中</div>
      <div class="flex flex-center m-t-20">
        <el-button v-if="userInfo.isManager" class="w-150" type="primary" round plain @click="cancelApplication">
          取消申请
        </el-button>
        <div v-if="userInfo.isManager" class="w-50"></div>
        <el-button
          v-if="companyInfo.servicePhone"
          class="w-150"
          type="primary"
          round
          plain
          @click="servicePhoneVisible = true"
        >
          拨打客服热线
        </el-button>
      </div>
    </div>
    <!--  企业账号（管理员）-商家拒绝-进入企业资料  -->
    <div v-if="isShowCompanies && companyState === 5" class="width-full flex-column flex-center">
      <div class="text-red font-size-20">商家审核不通过</div>
      <div class="flex flex-center m-t-20">
        <el-button v-if="userInfo.isManager" class="w-150" type="primary" round plain @click="againApplication">
          重新申请
        </el-button>
        <div v-if="userInfo.isManager" class="w-50"></div>
        <el-button
          v-if="companyInfo.servicePhone"
          class="w-150"
          type="primary"
          round
          plain
          @click="servicePhoneVisible = true"
        >
          拨打客服热线
        </el-button>
      </div>
    </div>

    <div v-if="isShowCompanies" class="font-size-16 font-weight-bold width-full">企业资料</div>
    <div v-if="isShowCompanies" class="flex1 overflow-auto width-full m-t-10">
      <el-row>
        <el-col :span="10">
          <el-form :model="formData" :rules="rules" ref="formRef" label-width="130px">
            <el-form-item prop="type" label="企业类型：">
              <el-select v-if="isEdit" v-model="formData.type" placeholder="请选择企业类型">
                <el-option v-for="item in typeOptions" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
              <div v-else>{{ typeOptionsObj[formData.type] }}</div>
            </el-form-item>
            <el-form-item prop="name" label="企业名称：">
              <el-input
                v-if="isEdit"
                v-model="formData.name"
                clearable
                maxlength="20"
                placeholder="请输入企业名称"
              ></el-input>
              <div v-else>{{ formData.name }}</div>
            </el-form-item>
            <el-form-item prop="bizLicenceCode" label="统一社会信用码：">
              <el-input
                v-if="isEdit"
                v-model="formData.bizLicenceCode"
                clearable
                maxlength="18"
                placeholder="请输入统一社会信用码"
              ></el-input>
              <div v-else>{{ formData.bizLicenceCode }}</div>
            </el-form-item>
            <el-form-item prop="contacts" label="联系人：">
              <el-input
                v-if="isEdit"
                v-model="formData.contacts"
                clearable
                maxlength="6"
                placeholder="请输入联系人"
              ></el-input>
              <div v-else>{{ formData.contacts }}</div>
            </el-form-item>
            <el-form-item prop="phone" label="联系电话：">
              <el-input
                v-if="isEdit"
                v-model="formData.phone"
                clearable
                maxlength="11"
                placeholder="请输入联系电话"
              ></el-input>
              <div v-else>{{ formData.phone }}</div>
            </el-form-item>
            <el-form-item prop="areaId" label="所在地区：">
              <SelectArea
                v-if="isEdit"
                ref="selectAreaRef"
                class="width-full"
                :areaId="formData.areaId"
                size="medium"
                placeholder="请选择所在地区"
                @change="areaChange"
              />
              <div v-else>{{ areaName }}</div>
            </el-form-item>
            <el-form-item prop="address" label="详细地址：">
              <el-input
                v-if="isEdit"
                v-model="formData.address"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 6 }"
                maxlength="50"
                placeholder="请输入详细地址"
              ></el-input>
              <div v-else>{{ formData.address }}</div>
            </el-form-item>
            <el-form-item prop="licencesPic" label="营业执照：">
              <div v-if="isEdit" class="flex">
                <UploadFileImg
                  style="width: 80px"
                  :maxSize="2"
                  listType="picture-card"
                  :limit="1"
                  :fileList="imgList"
                  :isMultiple="false"
                  :showFileList="true"
                  :hideFlag="true"
                  @getFileList="fileList => getImgFileList(fileList)"
                />
                <div class="m-l-10">图片大小2M以内</div>
              </div>
              <el-image
                v-if="!isEdit && imgList.length"
                style="width: 100px"
                :src="imgList[0].url"
                :preview-src-list="[imgList[0].url]"
              >
              </el-image>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
    <div v-if="isShowCompanies && isEdit" class="width-full">
      <el-divider v-if="isShowCompanies"></el-divider>
      <el-button v-loading="loading" @click="save" type="primary">
        保存（F2）
      </el-button>
    </div>
    <!--  请选择企业认证方式  -->
    <div v-if="!isShowCompanies && isGetCompaniesInfo" class="flex-column flex-center select-identity">
      <img class="width-full" :src="underReviewIcon" alt="" />
      <div class="text-999 font-size-16 text-center">
        <div>
          抱歉您暂未成立企业或加入任何企业
        </div>
        <div class="m-t-5">
          请选择企业认证方式
        </div>
      </div>
      <div @click="createEnterprise" class="select-identity-item flex flex-center m-t-20 width-full">
        <img class="w-30" src="@/assets/img/icons/icon-enterprise.png" alt="" />
        <div class="flex1 text-primary font-size-18 m-l-20">
          创建新企业
        </div>
        <svg
          t="1724048913638"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3530"
          width="32"
          height="32"
        >
          <path
            d="M514 114.3c-219.9 0-398.8 178.9-398.8 398.8 0 220 178.9 398.9 398.8 398.9s398.8-178.9 398.8-398.9c0-219.8-178.9-398.8-398.8-398.8z m232.1 440.4L582 718.8c-22.9 22.9-60.2 22.9-83.1 0-11.5-11.5-17.2-26.5-17.2-41.5s5.7-30.1 17.2-41.5l63.8-63.8H334c-32.5 0-58.8-26.3-58.8-58.8s26.3-58.8 58.8-58.8h228.7l-63.8-63.8c-22.9-22.9-22.9-60.2 0-83.1 22.9-22.9 60.2-22.9 83.1 0l164.1 164.1c22.9 23 22.9 60.2 0 83.1z"
            fill="#6589f8"
            p-id="3531"
          ></path>
        </svg>
      </div>
      <div v-if="false" @click="addEnterprise" class="select-identity-item flex flex-center width-full m-t-20">
        <img class="w-30" src="@/assets/img/icons/icon-enterprises.png" alt="" />
        <div class="flex1 text-primary font-size-18 m-l-20">
          加入已有企业
        </div>
        <svg
          t="1724048913638"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="3530"
          width="32"
          height="32"
        >
          <path
            d="M514 114.3c-219.9 0-398.8 178.9-398.8 398.8 0 220 178.9 398.9 398.8 398.9s398.8-178.9 398.8-398.9c0-219.8-178.9-398.8-398.8-398.8z m232.1 440.4L582 718.8c-22.9 22.9-60.2 22.9-83.1 0-11.5-11.5-17.2-26.5-17.2-41.5s5.7-30.1 17.2-41.5l63.8-63.8H334c-32.5 0-58.8-26.3-58.8-58.8s26.3-58.8 58.8-58.8h228.7l-63.8-63.8c-22.9-22.9-22.9-60.2 0-83.1 22.9-22.9 60.2-22.9 83.1 0l164.1 164.1c22.9 23 22.9 60.2 0 83.1z"
            fill="#6589f8"
            p-id="3531"
          ></path>
        </svg>
      </div>
    </div>
    <!--  拨打客服热线  -->
    <el-dialog title="拨打客服热线" :visible.sync="servicePhoneVisible" width="400px">
      <div class="font-size-16 p-a-20">
        <span>请拨打：</span>
        <span class="text-primary">{{ companyInfo.servicePhone }} </span>
        <span>联系商城咨询</span>
      </div>
      <div slot="footer" class="flex flex-center">
        <el-button class="width-full" type="primary" @click="servicePhoneVisible = false">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SelectArea from "@/components/selectArea/SelectArea.vue";
import UploadFileImg from "@/components/Upload/UploadFileImg.vue";
import { mobileValidate } from "@/utils/validate";
import {
  customerApplication,
  customerApplicationCancel,
  getCompaniesInfo,
  updateCompanies,
  createCompanies
} from "@/api/base";
import { getAddressList, getPositionAreaById } from "@/api/areaList";

const underReviewIcon = require("@/assets/img/under-review.png");

export default {
  name: "companyData",
  components: {
    SelectArea,
    UploadFileImg
  },
  data() {
    return {
      desc: "企业资料",
      underReviewIcon: underReviewIcon,
      keyName: "f2",
      keyScope: "ManageCompanyData",
      loading: false,
      servicePhoneVisible: false,
      isShowCompanies: false,
      isCreate: false,
      isGetCompaniesInfo: false,
      imgList: [],
      typeOptions: [
        { value: "garage", name: "汽修厂" },
        { value: "dealer", name: "经销商" },
        { value: "manufacturer", name: "生产厂家" }
      ],
      typeOptionsObj: {
        garage: "汽修厂",
        dealer: "经销商",
        manufacturer: "生产厂家"
      },
      formData: {
        name: "",
        bizLicenceCode: "",
        contacts: "",
        phone: "",
        areaId: 0,
        address: "",
        type: "",
        licencesPic: "",

        id: 0,
        authId: 0,
        createdBy: 0,
        createdAt: null,
        updatedBy: 0,
        updatedAt: null
      },
      areaName: "",
      addressOptions: [],
      rules: {
        type: [{ required: true, trigger: "change", message: "请选择企业类型" }],
        name: [{ required: true, trigger: "change", message: "请输入企业名称" }],
        contacts: [{ required: true, trigger: "change", message: "请输入联系人" }],
        phone: [
          { required: true, trigger: "change", message: "请输入联系电话" },
          { validator: mobileValidate, trigger: "blur" }
        ],
        areaId: [
          { required: true, trigger: "change", message: "请选择所在地区" },
          {
            validator: (rule, value, callback) => {
              if (value) {
                callback();
              } else {
                callback(new Error("请选择所在地区"));
              }
            },
            trigger: "blur"
          }
        ],
        address: [{ required: true, trigger: "change", message: "请输入详细地址" }]
      }
    };
  },
  computed: {
    companyInfo() {
      return this.$store.getters["companyInfo/companyInfo"];
    },
    userInfo() {
      return this.$store.getters["user/userInfo"];
    },
    isCompany() {
      return this.formData.id > 0;
    },
    companyState() {
      if (this.isCompany) {
        // 3,4,5
        if (this.userInfo.auditStatus === "未设置审核状态") {
          return 3;
        }
        if (this.userInfo.auditStatus === "审核中") {
          return 4;
        }
        if (this.userInfo.auditStatus === "审核失败") {
          return 5;
        }
      } else {
        // 1,2
        if (this.userInfo.auditStatus === "审核中") {
          return 1;
        }
        if (this.userInfo.auditStatus === "审核失败") {
          return 2;
        }
      }
      return 0;
    },
    isEdit() {
      if (this.isCompany) {
        // 企业员工 是否是管理员
        return this.userInfo.isManager;
      } else {
        return true;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
    this.getCompaniesInfo();
    //获取地址列表
    this.addressOptions = getAddressList();
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    // 快捷键
    handleKeyCode(e) {
      switch (e.code) {
        case "F2":
          e.preventDefault();
          e.stopPropagation();
          this.save();
          break;
      }
    },
    // 获取企业资料
    getCompaniesInfo() {
      getCompaniesInfo()
        .then(res => {
          this.formData = {
            ...this.formData,
            ...res
          };
          if (this.formData.id > 0) {
            this.isShowCompanies = true;
          }
          if (this.formData.areaId) {
            this.areaName = getPositionAreaById(this.formData.areaId, this.addressOptions).name;
          }
          if (this.formData.licencesPic) {
            this.imgList = [
              {
                url: this.formData.licencesPic,
                status: "success"
              }
            ];
          }
          this.isGetCompaniesInfo = true;
        })
        .catch(err => {
          this.$message.error(err.message || "获取企业资料失败");
        });
    },
    // 更新企业资料
    updateCompanies() {
      const data = {
        type: this.formData.type,
        name: this.formData.name,
        bizLicenceCode: this.formData.bizLicenceCode,
        contacts: this.formData.contacts,
        phone: this.formData.phone,
        areaId: this.formData.areaId,
        address: this.formData.address,
        licencesPic: this.formData.licencesPic
      };
      this.loading = true;
      updateCompanies(data)
        .then(() => {
          this.loading = false;
          this.$message.success("保存成功");
          this.getCompaniesInfo();
          // 获取用户信息
          this.$store.dispatch("user/getUserInfo");
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "保存企业资料失败");
        });
    },
    // 创建企业资料
    createCompanies() {
      const data = {
        type: this.formData.type,
        name: this.formData.name,
        bizLicenceCode: this.formData.bizLicenceCode,
        contacts: this.formData.contacts,
        phone: this.formData.phone,
        areaId: this.formData.areaId,
        address: this.formData.address,
        licencesPic: this.formData.licencesPic
      };
      this.loading = true;
      createCompanies(data)
        .then(() => {
          this.loading = false;
          this.isCreate = false;
          this.$message.success("保存成功");
          this.getCompaniesInfo();
          // 获取用户信息
          this.$store.dispatch("user/getUserInfo");
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "保存企业资料失败");
        });
    },
    // 保存
    save() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isCreate) {
            this.createCompanies();
          } else {
            this.updateCompanies();
          }
        }
      });
    },
    // 选择区域
    areaChange(area) {
      if (area && area.areaIds.length) {
        this.formData.areaId = area.areaIds[area.areaIds.length - 1];
      } else {
        this.formData.areaId = "";
      }
    },
    // 更新营业执照
    getImgFileList(fileList) {
      this.imgList = fileList;
      if (this.imgList.length) {
        this.formData.licencesPic = this.imgList[0].origin;
      } else {
        this.formData.licencesPic = "";
      }
    },
    // 创建新企业
    createEnterprise() {
      this.isShowCompanies = true;
      this.isCreate = true;
    },
    // 加入已有企业
    addEnterprise() {
      console.log("===", "name");
    },
    // 取消申请
    cancelApplication() {
      this.$confirm("是否确认取消此次申请？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        customerApplicationCancel()
          .then(() => {
            this.$message.success("取消申请成功");
            // 获取用户信息
            this.$store.dispatch("user/getUserInfo");
            // 获取企业资料
            this.getCompaniesInfo();
          })
          .catch(err => {
            this.$message.error(err.message || "取消申请失败！");
          });
      });
    },
    // 重新申请
    againApplication() {
      customerApplication()
        .then(() => {
          this.$message.success("重新申请成功");
          // 获取用户信息
          this.$store.dispatch("user/getUserInfo");
          // 获取企业资料
          this.getCompaniesInfo();
        })
        .catch(err => {
          this.$message.error(err.message || "重新申请失败！");
        });
    }
  }
};
</script>

<style lang="less">
.company-data-page {
  .select-identity {
    width: 300px;
    .select-identity-item {
      cursor: pointer;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #6589f8;
    }
  }
  .el-divider--horizontal {
    margin: 10px 0;
  }
}
.create-companies-dialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>
